import request from '@/utils/request';
import request2 from '@/utils/request2';

/**
 * 角色管理
 */
//推手业绩统计列表
export const distStatistList = (data)=> request('/pc/statis/distributor/performance',data);

//推手收益
export const distCommssionList = (data)=> request('/admin/userinfo/findUserExtend',data);

//推手收益类型
export const distfilterBoxList = ()=> request('/pc/distributorRole/filterBoxList');

//推手收益明细头部总计
export const distdetailCollect = (data)=> request('/pc/commission/distributor/detailCollect',data);

//推手收益明细列表
export const distdetailList = (data)=> request('/admin/userinfo/UserInfoOrderExtendRequest',data);

//推手列表统计
export const findPushTatal = (data)=> request('/admin/userinfo/findPushTatal',data);

//店员员业绩统计列表
export const employeeStatistList = (data)=> request('/pc/statis/mallEmployee/performance',data);

//员工提成
export const employeecommissionList = (data)=> request('/pc/commission/mallEmployee',data);

//员工提成明细汇总
export const employeedetailCollect = (data)=> request('/pc/commission/mallEmployee/detailCollect',data);

//员工提成明细列表
export const employeedetailList = (data)=> request('/pc/commission/mallEmployee/detailList',data);

//推手业绩统计明细
export const distStatistdetailList = (data)=> request('/pc/statis/distributor/performance/detailList',data);

//店员业绩统计明细
export const employeeperdetailList = (data)=> request('/pc/statis/mallEmployee/performance/detailList',data);

//提现中心
export const WithdrawalList = (data)=> request('/pc/commissionWithdrawal/list',data);

//发卷宝活动-列表
export const CouponGiftList = (data)=> request('/pc/ActivityCouponGift/List',data);

//发卷宝活动-添加编辑
export const CouponGiftEdit = (data)=> request('/pc/ActivityCouponGift/Edit',data);

//发卷宝活动-获取详细
export const CouponGiftModel = (data)=> request('/pc/ActivityCouponGift/Model',data);

//提现中心-审核通过
export const WithdrawalcheckSuccess = (data)=> request('/pc/commissionWithdrawal/checkSuccess',data);

//提现中心-审核否决
export const WithdrawalcheckFail = (data)=> request('/pc/commissionWithdrawal/checkFail',data);

//提现中心-修改提现账号
export const WithdrawalupdateAccount = (data)=> request('/pc/commissionWithdrawal/updateWithdrawalAccount',data);

//获取模式配置数据(推手) LEE
export const distributorconfig = (data)=> request('/admin/driverLevel/findAll',data);

//保存模式配置数据(推手) LEE
export const savedistributorconfig = (data)=> request('/admin/driverLevel/addDriverLevel',data);

//设置商城基本数据 LEE
export const savemallbaseinfo = (data)=> request('/pc/mall/savemallbaseinfo',data);

//设置商城基本数据 LEE
export const mallbaseinfo = (data)=> request('/pc/mall/mallbaseinfo',data);

//获取海报列表 LEE
export const infoposterlist = (data)=> request('/pc/infoposter/infoposterlist',data);

//删除海报LEE
export const infoposterdelete = (data)=> request('/pc/infoposter/infoposterdelete',data);

//新增海报 LEE
export const infoposteradd = (data)=> request('/pc/infoposter/infoposteradd',data);

//推手查询 LEE
export const distributorsearch = (data)=> request('/admin/userinfo/findPushAll',data);

//推手添加 LEE
export const memberdistributoradd = (data)=> request('/pc/member/memberdistributoradd',data);

//推手取消授权验证数据 LEE
export const memberdistibutorcancelvalitedata = (data)=> request('/pc/member/memberdistibutorcancelvalitedata',data);

//推手授权 waa
export const memberdistibutorAuth = (data)=> request('/admin/userinfo/auth',data);

//推手授权取消 LEE
export const memberdistibutorcancel = (data)=> request('/pc/member/memberdistibutorcancel',data);

//获取推手推荐列表 LEE
export const memberdistributorrecommendlist = (data)=> request('/pc/member/memberdistributorrecommendlist',data);

//推手概况 LEE
export const memberdistributorstatistics = (data)=> request('/pc/member/memberdistributorstatistics',data);

//通过手机号查询员工信息 LEE  店员查询使用
export const employeeinfobyphone = (data)=> request('/pc/employee/employeeinfobyphone',data);

//通过手机号查询信息 LEE  推荐人查询使用
export const recommendmemberinfobyphone = (data)=> request('/pc/member/memberinfobyphone',data);

//修改推手等级 LEE 
export const changememberdistributorrole = (data)=> request('/pc/member/changememberdistributorrole',data);

//修改直接推荐人 LEE
export const changememberdistributorrecommend = (data) => request('/pc/member/changememberdistributorrecommend', data);

//员工离职验证数据 LEE
export const employeedeparturevalitedata = (data) => request('/pc/employee/employeedeparturevalitedata', data);

//会员列表 新
export const vipFindAll = (data) => request('/admin/userinfo/findAll', data);

//会员详情 新
export const vipFindById = (data) => request('/admin/userinfo/findById', data);

//编辑会员信息 新
export const vipUpdateUser = (data) => request('/admin/userinfo/updateUser', data);

//查询优惠券名称
export const findByCouponName = (data) => request('/admin/card/findByCouponName', data);

//发放优惠券
export const grantUserCoupon = (data) => request('/admin/card/grantUserCoupon', data);

//优惠券发放记录
export const findGrantCoupon = (data) => request('/admin/card/findGrantCoupon', data);

//推手收益导出
export const UserExtendListExport = (data) => request('/admin/userinfo/UserExtendListExport', data);

//推手收益明细导出
export const UserExtendListDetailExport = (data) => request('/admin/userinfo/UserInfoOrderDetail', data);

//删除优惠券
export const delByUserIdCouponId = (data) => request('/admin/userinfo/delByUserIdCouponId', data);

//会员导出
export const UserInfoExcel = (data) => request('/admin/userinfo/UserInfoExcel', data);

//批量发放优惠券
export const tatchUserCoupon = (data) => request('/admin/card/tatchUserCoupon', data);

//分组优惠券
export const findComCoupon = (data) => request('/admin/card/findComCoupon', data);

//添加下单有礼活动
export const addOrUpActivity = (data) => request('/admin/orderPolite/addOrUpActivity', data);

//下单有礼删除
export const orderPolitedelById = (data) => request('/admin/orderPolite/delById', data);

//下单有礼活动列表
export const orderPolitefindAll = (data) => request('/admin/orderPolite/findAll', data);

//下单有礼活动详情
export const orderPolitefindOne = (data) => request('/admin/orderPolite/findOne', data);

//修改下单有礼活动
export const orderPoliteupdateAct = (data) => request('/admin/orderPolite/updateAct', data);

//优惠券赠送记录
export const findGiveCouponAll = (data) => request('/admin/giveCoupon/findGiveCouponAll', data);

//优惠券发放记录
export const findUserCouponAll = (data) => request('/admin/giveCoupon/findUserCouponAll', data);

/* 组合套餐 */
//套餐保存
export const comboAdd = (data) => request('/admin/combo/add', data);

//套餐详情
export const comboDetail = (data) => request('/admin/combo/detail', data);

//套餐列表
export const comboList = (data) => request('/admin/combo/list', data);

//套餐更新
export const comboUpdate = (data) => request('/admin/combo/update', data);

//套餐商品删除
export const comboGoodsDelete = (data) => request('/admin/combo/goods/delete', data);

//组合套餐删除
export const comboDelete = (data) => request('/admin/combo/delete', data);

//售后回滚
export const afterSaleBack = (data) => request('/admin/afterSale/rollback', data);

//复团商品 分页列表
export const afterGroupfindPage = (data) => request('/admin/afterGroup/findPage', data);

//复团商品 添加
export const afterGroupadd = (data) => request('/admin/afterGroup/add', data);

//复团商品 删除
export const afterGroupdel = (data) => request('/admin/afterGroup/del', data);

//添加售后备注
export const afterSaleremark = (data) => request('/admin/afterSale/remark', data);

//售后备注列表
export const afterSaleremarklist = (data) => request('/admin/afterSale/remark/list', data);

//支付宝打款记录
export const alipayRecord = (data) => request('/admin/afterSale/alipay/remit/list', data);


//批量发放优惠券
export const batchSendCoupon = (data) => request('/admin/userinfo/batch/send/coupon', data);
//批量发放优惠券模板下载
export const templatedownload = (data) => request('/admin/userinfo/template/download', data);
//批量发放优惠券模板导入前置
export function templatebeforeimport (data) {
    let formData = new FormData();
    for (let i in data) {
        formData.append(i, data[i]);
    }
    return request2({
        url: '/admin/userinfo/before/import',
        method: 'post',
        data: formData,
        withCredentials: false,
    })
}

//批量发放优惠券模板导入前置
export function batchSendFileCoupon (data) {
    let formData = new FormData();
    for (let i in data) {
		 formData.append(i, data[i]);
    }
    return request2({
        url: '/admin/userinfo/batch/send/coupon',
        method: 'post',
        data: formData,
        withCredentials: false,
    })
}

//会员详情编辑信息 --账号互换 切换手机号
export const phoneChangeSure = (data) => request('/admin/userinfo/exchange/account', data);

//积攒赢好礼活动api
//添加活动
export const addPromotionActivity = (data) => request('/admin/festival/addActivity', data);
//审核
export const auditPromotionActivity = (data) => request('/admin/festival/auditActivity', data);
//活动内容审核列表
export const auditActivityList = (data) => request('/admin/festival/auditActivityList', data);
//删除活动
export const delPromotionActivity = (data) => request('/admin/festival/deleteActivity', data);
//删除审核记录
export const deleteAuditActivity = (data) => request('/admin/festival/deleteAuditActivity', data);
//活动列表
export const promotionActivityList = (data) => request('/admin/festival/listActivity', data);
//线上活动内容删除
export const onlineActivityDelete = (data) => request('/admin/festival/onlineActivityDelete', data);
//线上活动内容列表
export const onlineActivityList = (data) => request('/admin/festival/onlineActivityList', data);
//线上活动内容编辑
export const onlineActivityUpdate = (data) => request('/admin/festival/onlineActivityUpdate', data);
//编辑活动
export const updateActivity = (data) => request('/admin/festival/updateActivity', data);
//获取活动详情
export const getpromotionActivity = (data) => request('/admin/festival/getActivity', data);
//营销活动入口-活动列表
export const chosepromotionActivity = (data) => request('/admin/festival/listActivityEntrance', data);

