<template>
	<div class="container" v-if="result" style="background-color: #fff;padding-top: 20px;font-size: 14px;">
		<div style="display: flex;align-items: center;background-color: #fff;font-size: 13px !important;margin-left: 10px;">
			<div style="position: relative;">
				<div style="position: absolute;top: 10px;right: 10px;border-radius: 10px;padding: 5px 15px;background-color: #2196F3;color: #fff;"
				@click="editVipInfo">编辑信息</div>
				<div class="header-table-border">
					<img v-if="vipInfo.headUrl" :src="vipInfo.headUrl" alt="" style="width: 80px;height: 80px;border-radius: 100%;">
					<img v-else src="" alt="" style="width: 80px;height: 80px;border-radius: 100%;">
					<div style="font-size: 16px;margin-top: 20px;">{{vipInfo.phoneNumber}}</div>
					<div class="vip-level">{{vipInfo.pusherLevel | getUserLevelName}}</div>
				</div>
			</div>
			<div style="border-right: 1px solid #EBEEF5;border-bottom: 1px solid #EBEEF5;">
				<div style="display: flex;align-items: center;height: 50px;border-top: 1px solid #EBEEF5;border-bottom: 1px solid #EBEEF5;">
					<div class="item-small">用户ID</div>
					<div class="item-big">{{vipInfo.id}}</div>
					<div class="item-small">锁粉推手</div>
					<div class="item-big" style="border-right: none;">{{vipInfo.invitendName}}</div>
				</div>
				<div style="display: flex;align-items: center;height: 50px;border-bottom: 1px solid #EBEEF5;">
					<div class="item-small">昵称</div>
					<div class="item-big">{{vipInfo.nickName}}</div>
					<div class="item-small">成为推手时间</div>
					<div class="item-big" style="border-right: none;">{{vipInfo.authorizationTime ? vipInfo.authorizationTime : '--'}}</div>
				</div>
				<div style="display: flex;align-items: center;height: 50px;border-bottom: 1px solid #EBEEF5;">
					<div class="item-small">性别</div>
					<div class="item-big">{{vipInfo.sex==0 ? '保密' : (vipInfo.sex==1 ? '男' : '女')}}</div>
					<div class="item-small">微信号</div>
					<div class="item-big" style="border-right: none;">{{vipInfo.wechatAccount}}</div>
				</div>
				<div style="display: flex;align-items: center;height: 50px;border-bottom: 1px solid #EBEEF5;">
					<div class="item-small">生日</div>
					<div class="item-big">{{vipInfo.birthDate}}</div>
					<div class="item-small">注册时间</div>
					<div class="item-big" style="border-right: none;">{{vipInfo.createTime}}</div>
				</div>
				<div style="display: flex;align-items: center;height: 50px;">
					<div class="item-small">城市</div>
					<div class="item-big">{{vipInfo.province}}-{{vipInfo.city}}-{{vipInfo.area}}</div>
					<div class="item-small">用户来源</div>
					<div class="item-big" style="border-right: none;">{{vipInfo.registerPlatform ==1 ? 'APP':'小程序'}}</div>
				</div>
			</div>
		</div>
		<div class="item-menu">
			<div style="padding-bottom: 30px;">统计信息</div>
			<div class="flex" style="width: 1200px;">
				<div style="width: 25%;text-align: center;">
					<div style="width: 100%;margin-bottom: 20px;">总订单数</div>
					<div style="width: 100%;"><span style="font-size: 30px;margin-right: 5px;">{{result.orderAmountTotalVO.countNum||0}}</span>笔</div>
				</div>
				<div style="width: 25%;text-align: center;">
					<div style="width: 100%;margin-bottom: 20px;">总消费金额</div>
					<div style="width: 100%;"><span style="font-size: 30px;margin-right: 5px;">{{result.orderAmountTotalVO.countAmount||'0.00'}}</span>元</div>
				</div>
				<div style="width: 25%;text-align: center;">
					<div style="width: 100%;margin-bottom: 20px;">本月订单</div>
					<div style="width: 100%;"><span style="font-size: 30px;margin-right: 5px;">{{result.orderAmountTotalVO.monthNum||0}}</span>笔</div>
				</div>
				<div style="width: 25%;text-align: center;">
					<div style="width: 100%;margin-bottom: 20px;">本月消费金额</div>
					<div style="width: 100%;"><span style="font-size: 30px;margin-right: 5px;">{{result.orderAmountTotalVO.monthAmount||'0.00'}}</span>元</div>
				</div>
			</div>
		</div>

		<div class="item-menu">
			<div style="padding-bottom: 30px;">收款账户</div>
			<el-table :data="bankList" style="margin-bottom: 20px;width: 100%;" border>
				<el-table-column prop="bankCardNumber" label="提现账号"></el-table-column>
				<!-- <el-table-column prop="bankCardName" label="银行名称"></el-table-column> -->
				<el-table-column prop="typeValue" label="账号类型"></el-table-column>
				<el-table-column prop="openBranch" label="支行信息"></el-table-column>
				<el-table-column prop="openPerson" label="姓名"></el-table-column>
				<el-table-column prop="openNumber" label="联系电话"></el-table-column>
			</el-table>
		</div>

		<div class="item-menu">
			<div style="padding-bottom: 30px;">优惠券记录</div>
			<el-table :data="couponList" style="margin-bottom: 20px;width: 100%;" border>
				<el-table-column prop="couponName" label="优惠券名称"></el-table-column>
				<el-table-column prop="faceMoney" label="面值/折扣"></el-table-column>
				<el-table-column prop="time" label="有效期" width="450px"></el-table-column>
				<el-table-column prop="createTime" label="领取时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div style="display:flex;">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="deleteCouponClick(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="item-menu">
			<div style="padding-bottom: 30px;">收货地址</div>
			<el-table :data="addressList" style="margin-bottom: 20px;width: 100%;" border>
				<el-table-column prop="receiverName" label="姓名"></el-table-column>
				<el-table-column prop="receiverPhone" label="手机号"></el-table-column>
				<el-table-column prop="address" width="300px" label="详细地址"></el-table-column>
				<el-table-column prop="addressCode" label="邮政编码"></el-table-column>
				<el-table-column label="默认地址">
					<template slot-scope="scope">
						<div style="display:flex;">
							<img v-if="scope.row.isDefault" src="../../assets/img/blue-check.png" alt="" style="width: 20px;height: 20px;margin-left: 30px;">
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="item-menu">
			<div style="padding-bottom: 30px;">订单记录</div>
			<el-table :data="orderList" style="margin-bottom: 20px;width: 100%;" border>
				<el-table-column prop="orderNo" label="订单编号"></el-table-column>
				<el-table-column prop="createTime" label="提交时间"></el-table-column>
				<el-table-column prop="receivePhone" label="用户账号"></el-table-column>
				<el-table-column prop="payableAmount" label="订单金额"></el-table-column>
				<el-table-column prop="paymentChannelValue" label="支付方式"></el-table-column>
				<el-table-column prop="orderSourceValue" label="订单来源"></el-table-column>
				<el-table-column prop="orderStatusValue" label="订单状态"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div style="display:flex;">
							<el-button type="text" style="margin-left: 15px;" class="pointers" @click="showOrderDetail(scope.row)">查看订单</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="编辑资料" :visible.sync="vipDialogVisible" v-if="vipDialogVisible" width="650px">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
					<el-form-item label="手机号" prop="phone">
						<div style="display: flex; align-items: center;">
							<el-input v-model="ruleForm.phone" maxlength="11" placeholder="请填写手机号" style="width:400px"></el-input>
							<div style="color: #d9001b;font-size: 14px;padding: 0 20px;" @click="phoneChangeDialog=true">账号互换</div>
						</div>
					</el-form-item>
					<el-form-item label="openId" prop="miniProOpenid">
						<el-input v-model="ruleForm.miniProOpenid" maxlength="30" placeholder="openId" style="width:400px"></el-input>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<el-radio-group v-model="ruleForm.sex">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="城市" prop="AddressArr">
						<el-cascader separator="-" style="width: 400px;" :props="props" :options="dataList" @change="handleAddressChange"
						v-model="ruleForm.AddressArr" ></el-cascader>
					</el-form-item>
					<el-form-item label="生日" prop="birthday">
						<el-date-picker v-model="ruleForm.birthday" style="width: 400px;" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
						type="date" placeholder="选择日期"></el-date-picker>
					</el-form-item>
					<el-form-item label="职业" prop="work">
						<el-input v-model="ruleForm.work" placeholder="请填写您的职业" maxlength="100" style="width:400px"></el-input>
					</el-form-item>
				</el-form>
				<!-- 按钮区域 -->
				<div style="text-align: center;margin-top:30px;">
					<el-button style="margin-right:30px;width: 120px;" @click="vipDialogVisible=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;" @click="saveChange()">保存</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="温馨提示" :visible.sync="deldialogVisible" width="30%">
			<span>确定要删除该条优惠券记录吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="deldialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="delSure()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="手机号互换" :visible.sync="phoneChangeDialog" width="650px">
			<div style="height: 250px;display: flex;flex-direction: column;justify-content: center;">
				<div style="display: flex; align-items: center;">
					<div style="margin-right: 10px;">现手机号：</div>
					<el-input v-model="ruleForm.phone" :disabled="true" style="width:400px"></el-input>
				</div>
				<div style="display: flex; align-items: center;margin-top: 60px;">
					<div style="margin-right: 10px;">新手机号：</div>
					<el-input v-model="changePhone" maxlength="11" placeholder="请输入需要互换的手机号" style="width:400px"></el-input>
				</div>
			</div>
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="phoneChangeDialog = false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="phoneChangeSure()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {delByUserIdCouponId, vipFindById, vipUpdateUser,phoneChangeSure} from '../../api/wyUsedInterface.js'
	import util from '@/utils/util'
	import axios from 'axios'

	export default {
		data() {
			let validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else if (value.length < 6 || value.length > 18) {
					callback(new Error('请输入6-18位密码'));
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass');
					}
					callback();
				}
			};
			let validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value.length < 6 || value.length > 18) {
					callback(new Error('请输入6-18位密码'));
				} else if (value !== this.ruleForm.newPwd) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			let validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请填写手机号'));
				} else {
					callback();
				}
			};
			return {
				id: 0,
				vipInfo: null,
				vipDialogVisible: false,
				ruleForm: {
					phone: '',
					vipLevel: 0,
					sex: 0,
					birthday: '',
					province: '',
					city: '',
					area: '',
					work: '',
					newPwd: '',
					nextPwd: '',
					AddressArr: [],
					miniProOpenid:'',
				},
				dataList: [],
				props: {
					label: 'name',
					value: 'name',
					children: 'children'
				},
				rules: {
					newPwd: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					nextPwd: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						trigger: 'blur',
						validator: validatePhone
					}]
				},
				bankList: [],
				addressList: [],
				orderList: [],
				result: null,
				couponList: [],
				deldialogVisible: false,
				currentRow: null,
				phoneChangeDialog:false,//账号互换弹窗
				changePhone:''
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.initData();
		},
		methods: {
			async initData() {
				this.dataList = util.getAddressList();
				try {
					let data = {
						id: this.id
					};
					let res = await vipFindById(data);
					if (res.success) {
						this.result = res.data;
						if(res.data.bankCard){
							res.data.bankCard.map(item=>{
								item.typeValue = item.type == 1 ?'支付宝':'银行卡';
								return item;
							})
							this.bankList = res.data.bankCard;
						}
						
						if (res.data.userCoupons) {
							this.couponList = res.data.userCoupons || [];
							this.couponList.map(item => {
								item.time = item.begTime + "~" + item.endTime;
								return item;
							})
						}
						this.vipInfo = res.data.userInfo;
						this.ruleForm.phone = this.vipInfo.phoneNumber;
						this.ruleForm.sex = this.vipInfo.sex; //0 未知，1 男；2：女
						this.ruleForm.birthday = this.vipInfo.birthDate || "";
						this.ruleForm.vipLevel = this.vipInfo.memberLevel;
						this.ruleForm.province = this.vipInfo.province;
						this.ruleForm.city = this.vipInfo.city;
						this.ruleForm.area = this.vipInfo.area;
						this.ruleForm.miniProOpenid = this.vipInfo.miniProOpenid;
						if (this.ruleForm.province && this.ruleForm.city && this.ruleForm.area) {
							this.ruleForm.AddressArr = [String(this.ruleForm.province), String(this.ruleForm.city), String(this.ruleForm.area)]
						} else {
							this.ruleForm.AddressArr = []
						}
						res.data.userInfo.addressList.map(item => {
							item.address = item.province + item.city + item.area + item.detailAddress;
							return item;
						})
						this.addressList = res.data.userInfo.addressList;
						//1 待付款 2 待发货 3 待收货 4 待评价 5 已完成 6 已关闭 7 售后中
						res.data.mainOrderList.map(item => {
							switch (item.orderStatus) {
								case 1:
									item.orderStatusValue = '待付款';
									break;
								case 2:
									item.orderStatusValue = '待发货';
									break;
								case 3:
									item.orderStatusValue = '待收货';
									break;
								case 4:
									item.orderStatusValue = '待评价';
									break;
								case 5:
									item.orderStatusValue = '已完成';
									break;
								case 6:
									item.orderStatusValue = '已关闭';
									break;
								case 7:
									item.orderStatusValue = '售后中';
									break;
								default:
									break;
							}
							//订单来源1小程序，2app
							item.orderSourceValue = item.orderSource == 2 ? 'App' : '小程序';
							//1 微信
							item.paymentChannelValue = item.paymentChannel == 2 ? '通联支付' : '微信';
							return item;
						})
						this.orderList = res.data.mainOrderList;
						console.log("---AddressArr---", this.ruleForm.AddressArr)
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			editVipInfo() {
				this.ruleForm.newPwd = "";
				this.ruleForm.nextPwd = "";
				this.changePhone = "";
				this.vipDialogVisible = true;
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.dataList = response.data
					})
					.catch(function() {})
			},
			handleAddressChange() {
				this.ruleForm.province = this.ruleForm.AddressArr[0]
				this.ruleForm.city = this.ruleForm.AddressArr[1]
				this.ruleForm.area = this.ruleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.name == this.ruleForm.province) {
						this.ruleForm.province = item.name
						item.children.map(items => {
							if (items.name == this.ruleForm.city) {
								this.ruleForm.city = items.name
								items.children.map(lastitems => {
									if (lastitems.name == this.ruleForm.area) {
										this.ruleForm.area = lastitems.name
									}
								})
							}
						})
					}
				})
			},
			saveChange() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						try {
							this.saveData();
						} catch (e) {
							//TODO handle the exception
						}
					}
				});
			},
			async saveData() {
				try {
					let data = {
						id: this.id,
						birthDate: this.ruleForm.birthday,
						// memberLevel: this.ruleForm.vipLevel,
						sex: this.ruleForm.sex,
						province: this.ruleForm.province,
						city: this.ruleForm.city,
						area: this.ruleForm.area,
						profess: this.ruleForm.work,
						phone:this.ruleForm.phone,
						openId:this.ruleForm.miniProOpenid,
					};
					let res = await vipUpdateUser(data);
					if (res.success) {
						this.vipDialogVisible = false;
						this.$message({
							showClose: true,
							type: 'success',
							message: "保存成功"
						});
						this.initData();
					} else {
						this.$message({type: 'error', message: res.alertMsg || '未知错误！'});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			showOrderDetail(row) {
				this.$router.push({
					path: '/order/orderDetailpage?Id=' + row.id
				});
			},
			deleteCouponClick(row) {
				this.currentRow = row;
				this.deldialogVisible = true;
			},
			async deleteCoupon() {
				try {
					let _this = this;
					let data = {
						userId: this.id,
						id: this.currentRow.id
					};
					let res = await delByUserIdCouponId(data);
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: "删除成功"
						});
						setTimeout(function() {
							var list = [];
							_this.couponList.map(item => {
								if (item.id != _this.currentRow.id) {
									list.push(item);
								}
								return item;
							})
							_this.couponList = list;
						}, 1000)
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.deldialogVisible = false;
				}
			},
			delSure() {
				this.deleteCoupon();
			},
			async phoneChangeSure(){
				let data = {
					userId: this.id,
					newPhoneNum:this.changePhone,
				};
				let res = await phoneChangeSure(data);
				if(res.success){
					this.$message({
						showClose: true,
						type: 'success',
						message: '手机号互换成功'
					});
					this.vipInfo.phoneNumber = this.changePhone;
					this.ruleForm.phone = this.changePhone;
					this.phoneChangeDialog = false;
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			}
		},
	}
</script>

<style scoped lang="less">
	.header-table-border {
		height: 250px;
		width: 250px;
		border: 1px solid #EBEEF5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #f8f8f9;
	}

	.item-small {
		width: 160px;
		border-right: 1px solid #EBEEF5;
		height: 100%;
		text-align: right;
		line-height: 50px;
		padding-right: 20px;
		background-color: #f8f8f9;
	}

	.item-big {
		width: 300px;
		border-right: 1px solid #EBEEF5;
		height: 100%;
		line-height: 50px;
		padding-left: 20px;
	}

	.vip-level {
		background-color: #f00;
		width: 90px;
		height: 30px;
		margin-top: 10px;
		line-height: 30px;
		text-align: center;
		border-radius: 15px;
		color: #fff;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.item-menu {
		padding: 30px;
	}

	.item-small-nowidth {
		height: 100%;
		text-align: center;
		line-height: 50px;
		background-color: #f8f8f9;
	}

	.item-big-nowidth {
		height: 100%;
		line-height: 50px;
		text-align: center;
	}
</style>
