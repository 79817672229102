import axios from 'axios'
import {Message} from 'element-ui'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 1000*180 // request timeout
});
service.interceptors.request.use(config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res && res.code && res.code !== 200) {
      Message({
        message: res.alertMsg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
      return res
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.alertMsg || '服务器繁忙，请稍后重试',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error)
  }
);

export default service
